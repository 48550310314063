<template>
  <div class="main">
    <newhead />
    <div class="ny-con">
      <grzxnav/>
      <div class="grzx-con">
        <el-divider content-position="left">写作记录</el-divider>
        <div class="table-filter">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="关键字">
              <el-input v-model="formInline.keywords" placeholder="请输入关键字" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :icon="Search" @click="getlist(1)">搜索</el-button>
              <el-button type="warning" :icon="Refresh" @click="chongzhi">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-divider />
        <div class="table-button">
          <el-button :icon="Download" type="primary" @click="piliangxiazai">批量下载</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" :selectable="selectable" width="50" />
          <el-table-column prop="title" label="标题" />
          <el-table-column label="写作状态" width="120">
            <template #default="scope">
              <span v-if="scope.row.rstatus == 1" style="color: #888"
                >待生成</span
              >
              <span v-if="scope.row.rstatus == 2" style="color: #e6a23c"
                >生成中</span
              >
              <span v-if="scope.row.rstatus == 3" style="color: #409eff"
                >已完成</span
              >
            </template>
          </el-table-column>
          <el-table-column label="审核状态" width="120">
            <template #default="scope">
              <span v-if="scope.row.shstatus == 1" style="color: #888"
                >待审核</span
              >
              <span v-if="scope.row.shstatus == 2" style="color: #409EFF"
                >已审核</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="ctime" label="提交时间" width="180" />
          <el-table-column label="备注" width="200">
            <template #default="scope">
              <span v-if="scope.row.usermark===null" style="font-weight:500"
                >-</span
              >
              <span v-if="scope.row.usermark" style="font-weight:500"
                >{{scope.row.usermark}}</span
              >
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button
                type="warning"
                v-if="scope.row.rstatus == 1 || scope.row.rstatus == 2 || scope.row.shstatus==1"
                size="small"
                @click="shuaxinzhuangtai"
              >
                刷新
              </el-button>
              <el-button
                v-if="scope.row.rstatus == 3 && scope.row.downds==1 && scope.row.shstatus==2"
                type="primary"
                size="small"
                @click="chucidownloadword(scope.row.Id,scope.row.shwurl)"
              >
                下载
              </el-button>
              <el-button
                v-if="scope.row.rstatus == 3 && scope.row.downds==2 && scope.row.shstatus==2"
                type="danger"
                size="small"
                @click="downloadword(scope.row.shwurl,scope.row.Id)"
              >
                重下
              </el-button>
              <el-button
                type="success"
                size="small"
                @click="addbeizhu(scope.row.Id,scope.row.usermark)"
              >
                备注
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tablesfy-fy">
          <el-pagination
            background
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totals"
            @size-change="handleSizeChange"
            @current-change="fanye"
          />
        </div>
      </div>
    </div>

    <!-- 备注的弹窗 -->
    <el-dialog v-model="dialogFormVisible" title="添加备注" width="500">
      <el-form>
        <el-form-item label="备注" :label-width="70">
          <el-input v-model="remark" type="textarea" rows="3" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="tianjiabeizhu">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>



    <newfooter />
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref,onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  Search,
  Refresh,
  Download
} from "@element-plus/icons-vue";

const router = useRouter();
// 弹窗显示
let dialogFormVisible=ref(false)
let remark=ref('')
// 需要添加备注的id
let beizhuid=ref('')


// 表格数据
const tableData = reactive([]);
let formInline=reactive({
  keywords:''
})
// 分页先关
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(10);

// 多选
let multipleSelection = reactive([])

// 重置
const chongzhi=()=>{
  formInline.keywords=''
  getlist(1)
}

// 获取列表
const getlist = (pg) => {
  tableData.length = 0;
  post("/api/Paper/GetUserPaperList", {
    lx:router.currentRoute.value.query.id,
    key:formInline.keywords,
    page: pg,
    psize: pageSize.value,
  })
    .then((res) => {
      totals.value = res.total;
      tableData.push(...res.data);
      currentPage.value = pg;
    })
    .catch((err) => {
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};
getlist(1);
// 分页
const fanye = (val) => {
  page.value = val;
  getlist(val);
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getlist(1);
};

// 刷新
const shuaxinzhuangtai = () => {
  getlist(1);
  ElMessage({
    message: "状态已更新！",
    duration: 1000,
  });
};
// 初次下载更改状态
const chucidownloadword=(id,urls)=>{
  get("/api/Paper/UserDownPaper",{
    id:id,
  }).then((res) => {
    downloadword(urls)
  });
}

// 下载文档
const downloadword = async(urls,id) => {
  return new Promise((resolve, reject) => {
    let downloadurl = urls;
    const link = document.createElement("a");
    link.setAttribute("target", '_blank'); 
    link.setAttribute("href", downloadurl); //设置下载文件的url地址
    //link.setAttribute('download', 'download'); //用于设置下载文件的文件名
    link.click();
    resolve();
  });
};

// 添加修改备注
const addbeizhu=(id,mark)=>{
  beizhuid.value=id
  remark.value=mark || ''
  dialogFormVisible.value = true
}
const tianjiabeizhu=()=>{
  post("/api/Paper/UpdateUserMark",{
    id:beizhuid.value,
    mark:remark.value,
  }).then((res) => {
    ElMessage({
      message: "提交成功！",
      duration: 1000,
      onClose:()=>{
        dialogFormVisible.value = false
        shuaxinzhuangtai()
      }
    });
  });
}

// 多选是否可以点击
const selectable = (row) => {
  return row.shstatus==2
}
// 多选
const handleSelectionChange = (val) => {
  multipleSelection.length=0
  if(val.length>0){
    multipleSelection.push(...val)
  }
}
// 批量下载
const piliangxiazai=async()=>{
  if(multipleSelection.length==0){
    ElMessage({
      message: "请勾选后下载",
      duration: 1000,
    });
    return
  }
  for(let i=0;i<multipleSelection.length;i++){
    await downloadword(multipleSelection[i].shwurl)
  }
}



</script>

<style scoped>
:deep(.el-divider--horizontal){
  margin: 0 0 15px 0;
}
:deep(.el-menu) {
  border-right: 0;
}
:deep(.el-divider__text) {
  font-weight: bold;
  font-size: 18px;
}
.main {
  background: #ebedf3;
}
.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1600px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
  margin-top: 50px;
}
.ny-con:after {
  content: "";
  display: table;
  clear: both;
}
.grzx-nav {
  float: left;
  width: 240px;
  min-height: 600px;
  padding-right: 30px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.grzx-con {
  float: right;
  width: calc(100% - 240px);
  height: auto;
  box-sizing: border-box;
  padding-left: 30px;
}
.grzx-con span {
  font-weight: bold;
}

.grzx-nav-tx {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.grzx-nav-yhm {
  width: calc(100% - 20px);
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.grzx-nav-st {
  padding: 20px 0;
}
.grzx-nav-yhm-lever {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url("/img/gerenzhongxin/VIP.png");
}
.grzx-nav-yhm-lever-num {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #fff;
  font-weight: bold;
}
.grzx-con-st {
  padding: 10px 0;
}
.grzx-con-st-list {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.grzx-con-st-list-title {
  width: 100px;
}
.grzx-con-st-list-txt {
  width: calc(100% - 100px);
}
.gezx-nr-cz {
  margin-top: 20px;
}
.tablesfy-fy{
  margin-top: 10px;
}
.table-button{
  margin: 0 0 20px 0;
}
.table-filter{
  padding: 20px 0 0 0;
}
</style>
